import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Directions from "./pages/Directions";
import Error404 from "./pages/Error404";

export default function Router() {
  const basePath = "/"
  return (
    <Routes>
      <Route path={basePath}>
        <Route path="" element={<Home />} />
        <Route path="directions" element={<Directions />} />
        <Route path="d" element={<Directions />} />
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  );
}
