import { Box, Text, Link, Heading } from '@chakra-ui/react'

function App() {
  return (
    <Box>
      <Box align='center'>
        <Heading size='xl' margin='10'>
          Page Not Found
        </Heading>
      </Box>
      <Box margin='10' align='center'>
        <Text><Link color='blue' href='/'>Go back to Villa Scooby</Link></Text>
      </Box>
    </Box>
  )
}

export default App
