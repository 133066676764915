import { Box, Text, Link, Heading, Image, Center } from '@chakra-ui/react'

function publicUrl(path) {
  return `${process.env.PUBLIC_URL}${path}`
}

// const airbnbUrl = "https://airbnb.com/h/villascooby";
const airbnbUrl = "https://airbnb.com/h/villaprivadascooby";

function App() {
  return (
    <Box
      bgColor='white'
      color='black'
      textAlign='center'
      paddingY='10'
      maxW='700px'
      margin='0 auto'
    >
      <Heading size='xl' marginBottom='10'>
        Villa Scooby
      </Heading>
      <Center>
        <Image
          src={publicUrl('photos/001-villa-front.jpg')}
          maxW='600'
          alt='Villa Scooby'
        />
      </Center>
      <Heading marginY='10' size='lg'>
        For booking
      </Heading>

      {/*
      <Text>
        WhatsApp:{" "}
        <Link color="blue" href="https://wa.me/85260970787" isExternal>
          +852 6097 0787
        </Link>
      </Text>
      */}
      <Text>Villa on a hill with sea view 🏖️🌅 12 min. walk from beach and city center.</Text>
      <Text><Link color="blue" href="https://photos.app.goo.gl/MBirpY5ig9njMTBp9" isExternal>Photos</Link></Text>
      <Text>
        AirBnB:{' '}
        <Link
          color='blue'
          href={airbnbUrl}
          isExternal
        >
          AirBnB
        </Link>
      </Text>
      <Text>
        Email:{' '}
        <Link color='blue' href='mailto:info@villascooby.com' isExternal>
          info@villascooby.com
        </Link>
      </Text>
      <Text>Phone/WhatsApp Reservation: <Link color="blue" href="https://wa.me/18496231152">+1 (849) 623-1152</Link> (Noelia, es/en/fr)</Text>
      <Heading marginY='10' size='lg' id='directions'>
        Directions
      </Heading>
      <Text marginY='10'>
        <Link
          color='blue'
          href='https://goo.gl/maps/hmnsX7uVSB7Rq2zHA'
          isExternal
        >
          Google Maps
        </Link>
      {" | "}
        <Link
          color='blue'
          href='/d'
        >
          Detailed Directions
        </Link>
      </Text>

      <Link href='/photos/directions.png'>
        <Image
          src={publicUrl('photos/directions.png')}
          alt='Villa Scooby Directions'
        />
      </Link>
    </Box>
  )
}

export default App
