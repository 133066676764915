import { Box, Text, Link, Heading, Image, Center } from '@chakra-ui/react'

function publicUrl(path) {
  return `${process.env.PUBLIC_URL}${path}`
}

function P({ children }) {
  return <Text margin="10">{children}</Text>
}

function App() {
  return (
    <Box
      bgColor='white'
      color='black'
      textAlign='center'
      paddingY='10'
      maxW='700px'
      margin='0 auto'
    >
      <Heading size='xl' marginBottom='10'>
        Villa Scooby Directions
      </Heading>
      <Link href='/photos/directions.png' download>
        <Image
          src={publicUrl('photos/directions.png')}
          alt='Villa Scooby Directions'
          />
      </Link>
      <P>
        <Link
          color='blue'
          href='https://goo.gl/maps/hmnsX7uVSB7Rq2zHA'
          isExternal
        >
          Google Maps
        </Link>
      </P>
      <P>
        Coming from the center of Las Terrenas, go on calle "Salomé Ureña" or
        "Calle 30 de Marzo" and keep going past Coco Dog. Our street is right
        {" "}<strong>after the street of Valcaoba</strong>. The street is <strong>in front of the gate of "El
        Flamboyan"</strong>.
      </P>
      <Center>
        <Image
          src={publicUrl('photos/street.jpg')}
          maxW='600'
          alt='Villa Scooby Street'
          />
      </Center>
      <P>
        The first white gate on the left is our gate (about 120m from the main street).
      </P>
      <Center>
        <Image
          src={publicUrl('photos/whitegate.jpg')}
          maxW='600'
          alt='White gate'
          />
      </Center>

      <P>
        When you arrive at the gate, contact us and we will open the gate for you. Our villa is the second one.
      </P>
      <Center>
        <Image
          src={publicUrl('photos/villa-bottom.jpg')}
          maxW='600'
          alt='Villa bottom'
          />
      </Center>
    </Box>
  )
}

export default App
